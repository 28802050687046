type State = {
  votersForGlobalUserActionsDisabled: Set<string>
}

const initialState: State = {
  votersForGlobalUserActionsDisabled: new Set<string>()
}

type Action = {
  type: "VOTE_USER_ACTIONS_DISABLED"
  data: {
    value: boolean
    voterId: string
  }
}

export default function currentViewerReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case "VOTE_USER_ACTIONS_DISABLED":
      const newVoters = new Set(state.votersForGlobalUserActionsDisabled)
      if (action.data.value)
        newVoters.add(action.data.voterId)
      else
        newVoters.delete(action.data.voterId)

      return { ...state, votersForGlobalUserActionsDisabled: newVoters }
  }

  return state
}

export function isUserActionsDisabled(state: State) {
  return state.votersForGlobalUserActionsDisabled.size > 0
}
