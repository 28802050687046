import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'

class BBMap extends BBIFrame {
  getUrl = ()  => `/${window.bbFolderName}/map/leafletWidgetDeploy171V1.html`

  constructor() {
    super()
    //console.log("BBMap Constructor");
    this.scrolling = 'no'
    this.noEvents = false;
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBMap',
      component: BBMap,
      label: 'Leaflet map',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the map. Use ?uri and ?geometry for WKT strings. Optionally use ?color for automatic coloring. hints for coloring #color:RGBA(0,255,0,1)@"values"infottext&extr text       #baselayer:PDOK grijs; #title:titel;',
        },
        {
          name: 'basequery',
          type: 'yasgui',
          label:
            'default for creating a map. when no parameters are available ',
        },
        {
          name: 'height',
          type: 'text',
          label: 'height variable',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },

        AfterJS.getJSScriptAfterSelectionChange(),
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  state = { selectedUri: null }
}
export default BBMap
