import { LoginAction, LoginState } from "../../redux/login"
import React, { useState } from "react"
import { login, LoginCredentials } from "../../api/login"
import { SubmitHandler, useForm } from "react-hook-form"
import { connect } from "react-redux"
import { LoginButtonBar, LoginPageLayout } from "./LoginPageLayout"
import { RouteComponentProps } from "react-router-dom"

const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

function LoginComponent(props: {
  loginState: LoginState,
  dispatch: (action: LoginAction) => void
} & RouteComponentProps) {
  const {
    register,
    handleSubmit,
    formState
  } = useForm<LoginCredentials>()
  const [ errorReason, setErrorReason ] = useState<string | null>(null)

  const doLogin: SubmitHandler<LoginCredentials> = async loginCredentials => {
    props.dispatch({ type: "LOGIN_REQUEST" })
    setErrorReason(null)
    const resp = await login(loginCredentials)

    if (resp.success) {
      props.dispatch({ type: "LOGIN_SUCCESS", payload: resp.user })

      if (window.location.pathname === '/login') {
        props.history.push('/')
      }
      window.location.reload()
    } else {
      props.dispatch({ type: "LOGIN_FAILURE" })
      setErrorReason(resp.reason)
    }
  }

  return (
    <LoginPageLayout>
      <h1>Welcome</h1>
      <form onSubmit={handleSubmit(doLogin)}>
        <label>
          Username

          <input type="email"
                 required
                 {...register('email', { required: true, pattern: EMAIL_PATTERN })} />
        </label>
        <label>
          Password

          <input type="password"
                 required
                 {...register('password', { required: true })} />
        </label>

        <LoginButtonBar disabled={formState.isSubmitting}
                        isLoggingIn={props.loginState.isLoggingIn}
                        error={errorReason} />
      </form>
    </LoginPageLayout>
  )
}

function mapStateToProps(state: any) {
  return { loginState: state.login }
}

export const Login = connect(mapStateToProps)(LoginComponent)
