
import { lazy } from 'react';

export default class BBEChartDescriptor {
  static getDescription() {
    return {
      name: 'BBEChart',
      component: lazy(() =>  import('./BBEChart')),
      label: 'BBEChart',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill the chart. first parameter is the type, second the amount for a pie/donut',
        },
        {
          name: 'charttype',
          type: 'select',
          label: 'charttype',
          options: [
            { label: 'Kies', value: 'Kies' },
            { label: 'Pie', value: 'Pie' },
            { label: 'Doughnut', value: 'Doughnut' },
            { label: 'Bar', value: 'Bar' },
            { label: 'Column', value: 'Col' },
            { label: 'stacked Column', value: 'ColStacked' },
            { label: 'stacked Bar', value: 'BarStacked' },
            { label: 'Gauge', value: 'gauge' },
          ],
        },
        {
          name: 'title',
          type: 'text',
          label: 'title',
        },
        {
          name: 'width',
          type: 'text',
          label: 'width css (97%)',
        },
        {
          name: 'height',
          type: 'text',
          label: 'height css  (95%) ',
        },
        {
          name: 'optionOptions',
          type: 'text',
          label: 'extra options for echart {"series":[{"bargap":0}]}'
        },
        {
          name: 'color',
          type: 'array',
          label: 'optional colorscheme series,hexcolor... ok,#ffaacc',
        },
        {
          name: 'legend',
          type: 'boolean',
          label: 'legend',
        },
        {
          name: 'defaultMenu',
          type: 'boolean',
          label: 'defaultMenu',
        },
        {
          name: 'stringToColor',
          type: 'boolean',
          label: 'use string values to color chart',
        },
        {
          name: 'gradientColors',
          type: 'boolean',
          label: 'use gradient colors',
        },
        {
          name: 'enableZoom',
          type: 'boolean',
          label: 'make the chart zoomable',
        },
        {
          name: 'labelsInChart',
          type: 'boolean',
          label: 'labels in the chart',
        },
        {
          name: 'popupLabelExtension',
          type: 'text',
          label: 'extension string for popup values (euro or stuks) ',
        },
        {
          name: 'popupLabelFixedFormat',
          type: 'text',
          label: '1 or 2 or..',
        },


        

      ],
    }
  }

  
}
