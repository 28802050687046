import React, { Component } from 'react'
import yasgui from '@triply/yasgui'
import '../../node_modules/@triply/yasgui/build/yasgui.min.css'
import '../../node_modules/@triply/yasqe/build/yasqe.min.css'

//import '../../node_modules/yasgui-yasqe/dist/yasqe.css'
 import { Button } from 'reactstrap';

class Yasgui extends Component {
  constructor(props) {
    super(props)
    this.textArea = React.createRef()
  }

  componentDidMount() {
   let me=this;
   
    //me.yas = yasgui.Yasqe.fromTextArea(me.textArea.current)
    me.yas = new yasgui.Yasqe(me.textArea.current);

    
   // window.top.yas=me.yas;
    
    

    me.yas.setValue(me.props.value || '')
    
    me.yas.on('blur', () => {
      me.props.handleBlur(me.yas.getValue())
    })
    

  }

  render() 
  {
    //return <input ref={this.textArea} type="textarea" />
    return <div ref={this.textArea}  />
  }
}

export default Yasgui
