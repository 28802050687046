import React, { Component } from 'react'
import styles from './SearchAutocomplete.module.css'
import { Input } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'
import PublishUtils from '../../helpers/PublishUtils'
import Icon from '@mui/material/Icon';


//magnifyingGlass from '../../images/magnifying-glass.svg';
//import cross from '../../images/cross.svg';

class SearchAutocomplete extends Component {
  state = {
    searchValue: '',
    validItems: [],
    showPopup: false,
    selectedIndex: 0
  }

  itemsRef = React.createRef()

  static getDescription() {
    return {
      name: 'SearchAutocomplete',
      component: SearchAutocomplete,
      label: 'Search autocomplete widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'Query to fill search results. parameters search string, uri and optionally ?icon',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'Variabele in which searchterm is published.',
        },
        {
          name: 'nullSelectionResetVariable',
          type: 'boolean',
          label: 'set reset value when nothing is selected',
        },
        {
          name: 'alignItems',
          type: 'select',
          label: 'Uitlijning',
          options: [
            {
              label: 'Stretch',
              value: 'stretch',
            },
            {
              label: 'Start',
              value: 'flex-start',
            },
            {
              label: 'Center',
              value: 'center',
            },
            {
              label: 'End',
              value: 'flex-end',
            },
          ],
        },
        PublishUtils.getResetValueDef(),
        {
          name: 'textbefore',
          type: 'text',
          label: 'text before the search input.',
        },
        {
          name: 'cssEscape',
          type: 'text',
          label: 'JSON object of CSS statements',
        },

        {
          name: 'resetPublishVariable',
          type: 'boolean',
          label: 'Reset the publish variable when sparql changes',
        },
        {
          name: 'placeholderText',
          type: 'text',
          label: 'Placeholder text for the search bar',
        },
      ],
    }
  }

  shouldComponentUpdate(e, b) {
    if (e == null) return true
    if (this.props == null) return true
    if (e.data !== this.props.data) {
      //console.log("NEW DATA. refresh current searchbox");

      if (this.props.resetPublishVariable === true) {
        let pv = this.props.publishVariable
        // console.log("should reset if variable is present",pv);
        if (pv != null) {
          // console.log("publish variable could be present");
          if (this.props.pubsub[pv] != null) {
            //console.log("reset publish variable ",pv,this.props.pubsub[pv]);

            this.setState({ searchValue: '' })
            this.props.publish(pv, 'http://www.buildingbits.nl/empty')
          }
        }
      }
    }
    return true
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!this.state.validItems.length) {
        return
      }

      let selectedItem = null;
      if (this.state.selectedIndex != null) {
        selectedItem = this.state.validItems[this.state.selectedIndex]
      } else if (this.state.validItems.length === 1) {
        selectedItem = this.state.validItems[0]
      }

      if (!selectedItem) {
        return
      }

      const objectKey = this.props.data.head.vars[0]
      const objectURIKey = this.props.data.head.vars[1]

      this.publish(selectedItem[objectURIKey], selectedItem[objectKey])
    } else if (e.key === 'ArrowDown') {
      e.preventDefault() // prevent cursor moving to end

      let selectedIndex = this.state.selectedIndex
      let validItems = this.state.validItems
      if (selectedIndex === validItems.length - 1) return
      this.setState({
        selectedIndex: selectedIndex === null ? 0 : selectedIndex + 1,
      })
      this.scrollToSelectedItem("down");
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()  // prevent cursor moving to start

      let selectedIndex = this.state.selectedIndex
      if (selectedIndex === 0) return
      this.setState({
        selectedIndex:
          selectedIndex === null
            ? this.state.validItems.length - 1
            : selectedIndex - 1,
      })
      this.scrollToSelectedItem("up");
    } else if (e.key === 'Escape') {
      this.setState({ searchValue: '' })
    }
  }

  /**
   * @param direction {"up"|"down"}
   */
  scrollToSelectedItem(direction) {
    requestAnimationFrame(() => {
      if (!this.itemsRef.current) return
      const selectedItem = this.itemsRef.current.querySelector('[data-is-selected=true]')
      if (!selectedItem) return
      if (isInView(this.itemsRef.current, selectedItem)) return
      selectedItem.scrollIntoView({ block: direction === "up" ? "start" : "end" })
    })
  }

  onChange = (e) => {
    let objectKey = this.props.data.head.vars[0]
    let searchValue = e.target.value

    let items = restructureResponse(this.props.data)

    // Always render items starting with search query on top
    const itemsStartingWithSearchQuery = items.filter(item =>
      item[objectKey] && item[objectKey].toLowerCase().startsWith(searchValue.toLowerCase())
    )
    const itemsOnlyContainingSearchQuery = items.filter(item =>
      item[objectKey]
      && item[objectKey].toLowerCase().includes(searchValue.toLowerCase())
      && !item[objectKey].toLowerCase().startsWith(searchValue.toLowerCase()))
    const validItems = [
      ...itemsStartingWithSearchQuery,
      ...itemsOnlyContainingSearchQuery
    ]

    this.setState({
      searchValue,
      showPopup: true,
      validItems,
      selectedIndex: 0,
    })
  }

  onBlur = (e) => {
    setTimeout(() => {
      this.setState({
        showPopup: false,
      })
    }, 300)
  }

  publish(objectURI, key) {
    let publishVariable = this.props.publishVariable
    var data = {}

    data[publishVariable] = objectURI

    this.props.publish(publishVariable, objectURI) //DIT EERST

    PublishUtils.resetParameter(this, false)
   
    this.setState({
      showPopup: false,
      searchValue: key,
    })
  }

  renderLabel(label, searchValue,icon) {
    let ind = label.toLowerCase().indexOf(searchValue.toLowerCase())
    if (ind === -1) {
      return <div><span>{label} </span> 
      {icon && (<span><Icon  fontSize="small" color="action" size="small" >{icon}</Icon></span>)}
      </div>
    }
    let end = ind + searchValue.length
    let l1 = label.slice(0, ind)
    let l2 = label.slice(ind, end)
    let l3 = label.slice(end)
    return (
      <div>
        <span>{l1}</span>
        <span style={{ fontWeight: 'bold' }}>{l2}</span>
        <span>{l3}</span>
        {icon && (<span><Icon  size="small"   fontSize="small" color="action" >{icon}</Icon></span>) }
      </div>
    )
  }

  renderValidItems() {
    try {
      if (!this.props.data) return

      let objectKey = this.props.data.head.vars[0]
      let objectURIKey = this.props.data.head.vars[1]
      let searchValue = this.state.searchValue
      let validItems = this.state.validItems
      let selectedIndex = this.state.selectedIndex
      let selectedItem = validItems[selectedIndex]

      if (!searchValue) return null
      if (!validItems.length)
        return <div className={styles.noResults}>geen resultaten gevonden</div>
      if (!this.state.showPopup) return null

      return (
        <div className={styles.items} z-index={200} ref={this.itemsRef}>
          {validItems.map((item, index) => {
            let isSelected =
              selectedItem && selectedItem[objectURIKey] == item[objectURIKey]
             let icon =item.icon;
            return (
              
              <div
             key={index}
                data-is-selected={isSelected}
                className={
                  styles.item + ' ' + (isSelected ? styles.selected : '')
                }
                onClick={() =>
                  this.publish(item[objectURIKey], item[objectKey])
                }
              >
                {this.renderLabel(item[objectKey], searchValue,icon)}
              
                
              </div>
             
             
            )
          })}
        </div>
      )
    } catch (ee) {
      console.log(ee)
    }
    return
  }

  emptyEvent=()=>
  {
   // console.log("empty event");
    this.setState({ searchValue: '' });
    if (this.props.nullSelectionResetVariable)
    {
      if (this.props.publishVariable!=null)
      {
        this.props.publish(this.props.publishVariable, "http://www.buildingbits.nl/reset") //DIT EERST
        PublishUtils.resetParameter(this, false)
      }
    }
  }

  render() {
    if (!this.props.data) return null
    let mainStyle = {
      alignItems: this.props.alignItems || 'stretch',
    }

    let css = this.props.cssEscape
    if (css) {
      try {
        css = JSON.parse(css)
        mainStyle = {
          ...mainStyle,
          ...css,
        }
      } catch (error) {
        console.log(error)
      }
    }
    let text = this.props.textbefore
    if (text != null && text.length < 1) {
      text = null
    }
    return (
      <div className={styles.main} style={mainStyle}>
        <div className={`${styles.inputContainer} ${styles[this.props.panelstyle]}`}>
          {text && <b className={styles.headertxt}>{text}</b>}
          <Input
            value={this.state.searchValue}
            component="input"
            type="text"
            placeholder={this.props.placeholderText}
            onKeyDown={this.handleKeyPress}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
          {this.state.searchValue ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={this.emptyEvent}
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGQ9Ik00NTcuNSw0NjZjLTMuNSwwLTYuOS0xLjMtOS41LTRMNDgsNjJjLTUuMy01LjMtNS4zLTEzLjgsMC0xOS4xYzUuMy01LjMsMTMuOC01LjMsMTkuMSwwbDQwMCw0MDBjNS4zLDUuMyw1LjMsMTMuOCwwLDE5LjENCgkJQzQ2NC40LDQ2NC43LDQ2MSw0NjYsNDU3LjUsNDY2eiIvPg0KCTxwYXRoIGQ9Ik01Ny41LDQ2NmMtMy41LDAtNi45LTEuMy05LjUtNGMtNS4zLTUuMy01LjMtMTMuOCwwLTE5LjFMNDQ4LDQzYzUuMy01LjMsMTMuOC01LjMsMTkuMSwwYzUuMyw1LjMsNS4zLDEzLjgsMCwxOS4xTDY3LDQ2Mg0KCQlDNjQuNCw0NjQuNyw2MSw0NjYsNTcuNSw0NjZ6Ii8+DQo8L2c+DQo8L3N2Zz4NCg=="
              alt="empty"
            />
          ) : (
            <img
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0yMzIuNjg5LDM0Ljc5N2MxMS44MjcsMi4xNTUsMjMuODI2LDMuNzI1LDM1LjUwNSw2LjYxM0MzODcuNDMxLDcwLjkzMyw0NDkuMTgxLDIwMS41ODQsMzk1LjgyLDMxMi4xMzMNCgljLTQuNjUzLDkuNTgyLTMuNTA3LDE0LjM1LDQuNCwyMS4wODhjNDQuNDIyLDM3Ljc1MiwyMi4xMTksMTguMjIzLDY2LjIyLDU2LjQzM2MyMS41NjksMTguNjU4LDIwLjc0NCw0Mi44NjItMS45NDgsNjAuMTQ2DQoJYy0xMy4xNTYsOS45NzEtMjguODgxLDkuMjM3LTQzLTIuODg4Yy00NC41Ni0zOC4zNy0yMi43MzgtMTkuMDAyLTY3LjE1OS01Ny40NjRjLTMuNzE0LTMuMjMyLTcuNDczLTYuMzUtMTEuNzgyLTkuODc5DQoJYy0yNy4wNDcsMjAuNjI5LTU3LjAyOCwzMy42NDgtOTAuMzEsMzkuNDcxQzE0My4xNDcsNDM4LjIyNCwzNC45NywzNTEuNDY3LDMwLjM2MiwyNDAuNzc5DQoJQzI1LjkwNCwxMzMuODg2LDk4LjE1Miw0OC45OTcsMjAzLjU5MSwzNi45NTJjMi41NTYtMC4yODcsNS4wNDItMS40MSw3LjU1My0yLjE1NUMyMTguMzA2LDM0Ljc5NywyMjUuNTAzLDM0Ljc5NywyMzIuNjg5LDM0Ljc5N3oNCgkgTTM2NS4zMzUsMjI4Ljg4M2MwLjE4NC03Ny45NTYtNjEuODQyLTE0MC40MTYtMTM5LjgzMi0xNDAuNzgzYy03Ny44NTItMC4zNzgtMTQwLjgyOCw2MS44NDItMTQxLjE5NSwxMzkuNDg4DQoJYy0wLjQsNzguMzU3LDYyLjMzNSwxNDEuNDgyLDE0MC41NjUsMTQxLjUwNEMzMDIuNjIyLDM2OS4xODUsMzY1LjE5NywzMDYuNzI1LDM2NS4zMzUsMjI4Ljg4M3oiLz4NCjwvc3ZnPg0K"
              alt="magnifying glass"
            />
          )}
        </div>
        {this.renderValidItems()}
      </div>
    )
  }
}

/**
 * @param container {HTMLElement}
 * @param element {HTMLElement}
 * @return {boolean}
 */
function isInView(container, element) {
  const cTop = container.scrollTop
  const cBottom = cTop + container.clientHeight

  const eTop = element.offsetTop
  const eBottom = eTop + element.clientHeight

  return eTop >= cTop && eBottom <= cBottom
}

export default SearchAutocomplete
