import { Component } from 'react'
import styles from './List.module.css'

import { Button } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'
import PublishUtils from './../../helpers/PublishUtils'

class List extends Component {
  state = { checklist: {},all:false }


  handleAllOnChange = (e) => {
    try
    {
    let all=e.target.checked;
   
    let key = this.props.data.head.vars[0]
    let label = this.props.data.head.vars[0]
    if (this.props.data.head.vars.length > 1) {
      key = this.props.data.head.vars[1]
    }
   

    let checklist = this.state.checklist;
    for (var n in this.props.data.results.bindings)
    {
      var kvalue = this.props.data.results.bindings[n][key].value;
      checklist[kvalue]=all;
    }
   
    let publishVariable = this.props.publishVariable;
    if (publishVariable != null) {

      var valueString=PublishUtils.getMultiplePropValueAsSingleValue(this.props.multipleselectparametertype,checklist);

          console.log(valueString,checklist);
      this.props.publish(publishVariable, valueString);

      //PublishUtils.resetParameter(this);
    }
    this.setState({ checklist: checklist,all:all });
  }
  catch(e){}
  }

  handleOnChange = (e) => {
    // setIsChecked(!isChecked);
    // console.log(e,e.target.value,e.target.checked);
    let checklist = this.state.checklist
    checklist[e.target.value] = e.target.checked

    this.setState({ checklist: checklist })
    let publishVariable = this.props.publishVariable
    if (publishVariable != null) {

      var valueString=PublishUtils.getMultiplePropValueAsSingleValue(this.props.multipleselectparametertype,checklist);
      console.log(valueString,publishVariable);
      this.props.publish(publishVariable, valueString)
      //PublishUtils.resetParameter(this);
    }
  }

  static getDescription() {
    return {
      name: 'List',
      component: List,
      label: 'List widget',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de lijst',
        },
        {
          name: 'query',
          type: 'yasgui',
          label: 'first variabele for the labels ',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'sparql parameter name for publishing select event result',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label: 'Published variabele on which to listen to to define list item selection.',
        },
        {
          name: 'multipleselect',
          type: 'boolean',
          label: 'allow multiple selections. parameters are filled as comma separated values',
        },
        {
          name: 'allMultipleSelect',
          type: 'boolean',
          label: 'Add "All" multiple select',
        },
        PublishUtils.getMultipleValueFormatOptions(),
        PublishUtils.getResetValueDef(),
        {
          name: 'customStyling',
          type: 'boolean',
          label: 'Enable Custom Styling',
        },
        {
          name: 'areaBorderStyle',
          type: 'text',
          label: 'Custom Styling: area-border-style (example: "3px solid grey"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border',
        },
        {
          name: 'areaBorderRadius',
          type: 'text',
          label: 'Custom Styling: area-border-radius (examples: "0.8em", "1.2em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
        },
        {
          name: 'textColor',
          type: 'text',
          label: 'Custom Styling: text-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'bgColor',
          type: 'text',
          label: 'Custom Styling: background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'selectedTextColor',
          type: 'text',
          label: 'Custom Styling: text-color of selection (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'selectedBgColor',
          type: 'text',
          label: 'Custom Styling: background-color of selection (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'itemBorderStyle',
          type: 'text',
          label: 'Custom Styling: border between each list-item (examples: "3px solid grey")'
        },
        {
          name: 'itemPaddingVertical',
          type: 'text',
          label: 'Custom Styling: vertical padding of each list-item (examples: "0.8em", "1.4em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/padding-top',
        },
        {
          name: 'itemPaddingHorizontal',
          type: 'text',
          label: 'Custom Styling: horizontal padding of each list-item (examples: "0.4em", "1.0em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/padding-left',
        },
        /*,
    {
      name: 'clearParameter',
      type: 'text',
      label: 'clears widget when parameter changes'
    }*/
      ],
    }
  }

  //state= {clearList:false};
  clearList = false

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps == null) {
      return false
    }
    if (this.props == null) {      return false    }
    if (this.props.data == null) {       return true    }

    if (nextProps.data !== this.props.data) {
    
     


      this.setState({ checklist: {},all:false })
      let publishVariable = this.props.publishVariable
      if (publishVariable != null) {

       // publish empty but use the right format
       let r=PublishUtils.getEmptyMultiplePropValueAsSingleValue(this.props.multipleselectparametertype)
        this.props.publish(publishVariable, r)

        PublishUtils.resetParameter(this)
      }

      this.setState({ checklist: {} })

      return true
    }

    return true
  }

  renderMultiple() {

    let allMultipleSelect = this.props.allMultipleSelect;
    let all=this.state.all;
    let key = this.props.data.head.vars[0]
    let label = this.props.data.head.vars[0]
    if (this.props.data.head.vars.length > 1) {
      key = this.props.data.head.vars[1]
    }

    // let publishVariable = this.props.publishVariable

    let items = restructureResponse(this.props.data)
    //console.log(key,items);
    let checklist = this.state.checklist
    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <ul className={styles.main}>
          {allMultipleSelect && (
            <div>
              <input
                value="all"
                type="checkbox"
                checked={all}
                onChange={this.handleAllOnChange}
                disabled={this.props.userActionsDisabled}
              ></input>
              <span> All</span>
            </div>
          )}

          {items.map((item, index) => {
            let checkboxvalue = item[key]
            let check = checklist[checkboxvalue] ?? false

            return (
              <div key={index}>
                <input
                  value={item[key]}
                  type="checkbox"
                  checked={check}
                  onChange={this.handleOnChange}
                  disabled={this.props.userActionsDisabled}
                ></input>
                <span> {item[label]}</span>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    if (!this.props.data) return null
    if (this.props.multipleselect) return this.renderMultiple()

    let key = this.props.data.head.vars[0]
    let label = this.props.data.head.vars[0]
    if (this.props.data.head.vars.length > 1) {
      key = this.props.data.head.vars[1]
    }

    let publishVariable = this.props.publishVariable

    let items = restructureResponse(this.props.data)
    //console.log(key,items);
    let selectedItem = this.props.pubsub[publishVariable]

    return (
      <div
        className={`${styles.main} inheritFromArea`}
        data-custom-styling={Boolean(this.props.customStyling)}
        style={{
          '--text-color': this.props.textColor,
          '--selected-text-color': this.props.selectedTextColor,
          '--bg-color': this.props.bgColor,
          '--selected-bg-color': this.props.selectedBgColor,
          '--item-border-style': this.props.itemBorderStyle,
          '--item-padding-vertical': this.props.itemPaddingVertical,
          '--item-padding-horizontal': this.props.itemPaddingHorizontal,
        }}
      >
        {this.props.title && <div className={styles.title}>{this.props.title}</div>}
        <ul>
          {items.map((item, index) => {
            if (publishVariable) {
              return (
                <li key={index} data-selected={selectedItem === item[key]}>
                  <Button
                    color="link"
                    className={''}
                    style={{ 'text-align': 'left', padding: '0px' }}
                    onClick={() => {
                      this.props.publish(publishVariable, item[key])
                      PublishUtils.resetParameter(this)
                    }}
                  >
                    {item[label]}
                  </Button>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <div style={{ padding: '2px 1px 1px' }}>{item[label]}</div>
                </li>
              )
            }
          })}
        </ul>
      </div>
    )
  }
}

export default List
