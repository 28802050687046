import { Component } from 'react'
import { connect } from 'react-redux'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//import { HashRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home'
import Users from './components/Users'
import Project from './components/Project'
import Viewer from './components/Viewer'
import Command from './helpers/Command'
import { enableMapSet } from 'immer'
import { setupUserInputMessenger } from './helpers/user-input-messenger'
import { getCurrentUser } from "./api/login"
import { Login } from "./components/login/Login"
import { RepoLogin } from "./components/login/RepoLogin"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { VirtuosoImportExport } from "./components/virtuoso/VirtuosoImportExport"
import Spinner from "./components/Spinner"

enableMapSet()

const ERR_INVALID_RESPONSE_WITH_SUGGESTION =
  'Invalid response! When developing use `yarn dev`.'

class App extends Component {

  state = {
    isFetchingLoginInfo: false
  }

  componentDidMount() {
    this.wistorArt();
   
    setupUserInputMessenger()

    if (!window.CONNECTED_APPS_STATIC) {
      this.fetchLoginInfo()
    }
  }

  async fetchLoginInfo() {
    this.setState({ isFetchingLoginInfo: true })
    this.props.dispatch({ type: "LOGIN_REQUEST" })
    try {
      const user = await getCurrentUser()
      if (user) {
        this.props.dispatch({ type: "LOGIN_SUCCESS", payload: user })
      } else {
        this.props.dispatch({ type: "LOGIN_FAILURE" })
      }
    } catch (error) {
      console.error(error)
      this.props.dispatch({ type: "LOGIN_FAILURE" })
    } finally {
      this.setState({ isFetchingLoginInfo: false })
    }
  }


  wistorArt()
  {
   
   console.log(" __      __.__          __                \r\n"
   + "/  \\    /  \\__| _______/  |_  ___________ \r\n"
   + "\\   \\/\\/   /  |/  ___/\\   __\\/  _ \\_  __ \\\r\n"
   + " \\        /|  |\\___ \\  |  | (  <_> )  | \\/\r\n"
   + "  \\__/\\  / |__/____  > |__|  \\____/|__|   \r\n"
   + "       \\/          \\/                     \r\n");

   console.log("version 0.32");


  }

  render() {
   
    window.top.command=Command;
    

    if (window.CONNECTED_APPS_STATIC) {
      return (
        <Router>
          <Switch>
              <Route path="/" exact component={Viewer} />
              <Route path="/loginbb" component={RepoLogin} />
              <Route path="*" component={Viewer} />
          </Switch>
        </Router>
      )
    }

    if (this.state.isFetchingLoginInfo) {
      return <Spinner />
    }

    return (
      <Router>
        <div className="app">
          <ProtectedRoute path="/" exact component={Home} />
          <Route path="/loginbb" component={RepoLogin} />
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/users" component={Users} />
          <ProtectedRoute path="/projects/:projectPath" exact component={Project} />
          <Route
            path="/projects/:projectPath/:viewerPath"
            exact
            component={Viewer}
          />
          <ProtectedRoute
            path="/projects/:projectPath/:viewerPath/:mode(edit)"
            component={Viewer}
          />
          <ProtectedRoute path="/virtuoso/importexport"
                          component={VirtuosoImportExport} />
        </div>
      </Router>
    )
  }
}

export default connect()(App)
