import { Component } from 'react'
import { Button } from 'reactstrap'
import styles from './Header.module.css'
import person from '../images/person.svg'
import gear from '../images/gear.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grow } from '@mui/material'
import Command from '../helpers/Command'
import infoIcon from '../images/info.svg'
import { AutoHyperlink } from './AutoHyperlink'
import { logout } from '../api/login'

const BACKUP_LOGO = '/logos/logo-wistor.png'

class Header extends Component {
  state = { collapsed: true }

  logoutRepoOrPlatform = async () => {
    let bbsparql = this.props.bbsparqlendpoint
    if (bbsparql != null) {
      Command.sendCommand(this, 'logout', null, function () {
        window.location.reload(false)
      })
    } else {
      console.log('logout from config mongo/nodejs platform')
      await logout()
      window.location.reload()
      // localStorage.removeItem('kvk-vb-token');
    }

    //window.location.reload(false);
  }

  login = () => {
    this.props.history.push('/login', this.props.location.pathname)
  }

  onClickManageUsers = () => {
    this.props.history.push('/users', this.props.location.pathname)
  }

  mouseOver = (e) => {
    console.log('in')
    // this.setState({collapsed:false});
  }
  mouseOut = (e) => {
    console.log('out')
    // this.setState({collapsed:true});
  }

  render() {
    /** @type {LoginState} */
    const loginState = this.props.loginState
    const collapsed = this.state.collapsed
    var logoutbutton =this.props.logoutbutton;
    if (logoutbutton==null) logoutbutton=false;

    const showLogoutButton = !loginState.isLoggingIn
      // always show logout button for platform users
      && (logoutbutton || loginState.isLoggedIn)

    const showLoginButton = !loginState.isLoggingIn
      && !showLogoutButton
      && !loginState.isLoggedIn
      && !this.props.noLoginButton

    return (
      <Grow direction="up" appear={true} timeout={1000} in={collapsed}>
        <div className={`${styles.wrapper} ${this.props.modern ? styles.modern : ''}`}>
          <div
            className={styles.main}
            style={{ maxWidth: this.props.pageWidth }}
          >
            <img
              src={this.props.logoURL || BACKUP_LOGO}
              alt="logo"
              style={{ maxHeight: '40px', marginRight: '6px' }}
            />

            <div className={styles.centerPart}>
              <span
                className={styles.title}
                style={{ float: this.props.alignTitle || 'right' }}
              >
                {this.props.title || ''}
                {this.props.onInfoClick && (
                  <img
                    src={infoIcon}
                    alt="box"
                    className={styles.infoIcon}
                    onClick={() => this.props.onInfoClick()}
                  />
                )}
                {this.props.onSettingsClick && (
                  <img
                    onClick={this.props.onSettingsClick}
                    src={gear}
                    alt="gear"
                    style={{ paddingLeft: '12px', cursor: 'pointer' }}
                  />
                )}
              </span>
              <AutoHyperlink text={this.props.subtitle} />
            </div>
            <div className={styles.auth}>
              {(!loginState.isLoggingIn && loginState.isLoggedIn) && (
                  <>
                    <img src={person} alt="person" />
                    <div className={styles.nameContainer}>
                      <div style={{ lineHeight: '1.1em' }}>
                        {loginState.user?.name}
                      </div>
                      {(loginState.user?.role === 'admin' || loginState.user?.role === 'superadmin') && (
                        <Button
                          color="link"
                          style={{ padding: '0', fontSize: '0.8em' }}
                          onClick={this.onClickManageUsers}
                        >
                          Gebruikers beheren
                        </Button>
                      )}
                    </div>
                  </>
              )}
              {showLogoutButton && (
                <Button color="link" onClick={this.logoutRepoOrPlatform}>
                  Log out
                </Button>
              )}
              {showLoginButton && (
                <Button color="primary" onClick={this.login}>
                  Login
                </Button>
              )}
            </div>
          </div>
        </div>
      </Grow>
    )
  }
}

const mapStateToProps = (state) => ({
  loginState: state.login,
})

Header = withRouter(Header)

export default connect(mapStateToProps)(Header)
