import { lazy } from 'react';
import PublishUtils from '../../../../helpers/PublishUtils';



export default class StartRuleButtonDescriptor  {
 
  static getDescription() {
    return {
      name: 'StartRuleButton',
      component: lazy(() =>  import('./StartRuleButton')),
      label: 'StartRuleButton',
      variables: [
           
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
            name: 'title',
            type: 'text',
            label: 'button label',
          },
          {
            name: 'beginIcon',
            type: 'text',
            label: 'begin icon',
          },
          {
            name: 'endIcon',
            type: 'text',
            label: 'end icon',
          },
          {
            name: 'tooltip',
            type: 'text',
            label: 'button tooltip',
          },
          {
            name: 'startRule',
            type: 'text',
            label: 'start rule with tag',
          },
         
          {
            name: 'parameters',
            type: 'text',
            label:
              'extra parameters send in JSON format,Parameters will be resolved before sending. keep it simple. no subchildren',
          },
        
       
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
       
      ]
    }
  }

  
}
