import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Users.module.css'
import orderBy from 'lodash/orderBy'
import Header from './Header'
import Spinner from './Spinner'
import add from '../images/add.svg'
import { Button, Input, FormGroup, Label } from 'reactstrap'
import Dialog from './Dialog'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import { fetchProjects } from '../redux/projects'
import { fetchUsers, createUser } from '../redux/users'
import BackButton from './BackButton'

class Users extends Component {
  state = { values: {} }

  componentDidMount() {
    this.props.fetchProjects((error) => {
      if (error.response.status === 401) {
        this.props.history.push('/login', 'users')
      }
    })
    this.props.fetchUsers((error) => {
      if (error.response.status === 401) {
        this.props.history.push('/login', 'users')
      }
    })
  }

  onDialogOkClick = () => {
    this.props.createUser(this.state.values, (error) => {
      if (error.response.status === 401) {
        this.props.history.push('/login', 'users')
      }
    })
    this.setState({ dialogVisible: false })
  }

  render() {
    if (this.props.users.loading || this.props.projects.loading)
      return <Spinner />
    if (!this.props.projects.loaded || !this.props.users.loaded) return null

    /** @type {LoginState} */
    const loginState = this.props.loginState

    let users = this.props.users.items
    let projects = this.props.projects.items
    // users = orderBy(users, ['createdAt'], ['asc']);
    users = orderBy(users, ['name'], ['asc'])
    return (
      <div>
        <Helmet>
          <title>Connected Apps</title>
          <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
        </Helmet>
        <BackButton />
        <Header />
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.title}>Gebruikers</div>
              <div className={styles.users}>
                <div className={styles.tableContainer}>
                  <table>
                    <thead>
                      <tr>
                        <th>Naam</th>
                        <th>Email</th>
                        <th>Rol</th>
                        {loginState.user?.role === 'superadmin' && <th>Project</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                        return (
                          <tr className={styles.user} key={user.id}>
                            <td
                              style={{ fontSize: '1.1em', fontWeight: '500' }}
                            >
                              {user.name}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            {loginState.user?.role === 'superadmin' && (
                              <td>{user.project && user.project.title}</td>
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                {loginState.isLoggedIn && (
                  <div
                    className={styles.add}
                    onClick={() => this.setState({ dialogVisible: true })}
                  >
                    <div>
                      <img src={add} alt="add" style={{ width: '24px' }} />
                      <span
                        style={{ verticalAlign: 'middle', paddingLeft: '12px' }}
                      >
                        Nieuwe gebruiker aanmaken...
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>

        {this.state.dialogVisible && (
          <Dialog>
            <FormGroup>
              <Label>Name</Label>
              <Input
                className="dark-form-control"
                value={this.state.values.name || ''}
                type="text"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, name: e.target.value },
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Email</Label>
              <Input
                className="dark-form-control"
                value={this.state.values.email || ''}
                type="text"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, email: e.target.value },
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Wachtwoord</Label>
              <Input
                className="dark-form-control"
                value={this.state.values.password || ''}
                type="text"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, password: e.target.value },
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Rol</Label>
              <Input
                className="dark-form-control"
                value={this.state.values.role || ''}
                type="select"
                onChange={(e) =>
                  this.setState({
                    values: { ...this.state.values, role: e.target.value },
                  })
                }
              >
                <option value="">Kies een rol</option>
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
                <option value="admin">Administrator</option>
              </Input>
            </FormGroup>
            {loginState.user?.role === 'superadmin' && (
              <FormGroup>
                <Label>Project</Label>
                <Input
                  className="dark-form-control"
                  value={this.state.values.project || ''}
                  type="select"
                  onChange={(e) =>
                    this.setState({
                      values: { ...this.state.values, project: e.target.value },
                    })
                  }
                >
                  <option value="">Kies een project</option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.title}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
            <div style={{ textAlign: 'center' }}>
              <Button
                color="primary"
                style={{ minWidth: '120px', margin: '12px' }}
                onClick={() => this.setState({ dialogVisible: false })}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                style={{ minWidth: '120px', margin: '12px' }}
                onClick={this.onDialogOkClick}
              >
                OK
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  users: state.users,
  loginState: state.login
})

export default connect(mapStateToProps, {
  fetchProjects,
  fetchUsers,
  createUser,
})(Users)
