import './bbutil'
import BBIFrame from './BBIFrame'
import * as FileSaver from 'file-saver'
//import * as XLSX from "xlsx";
import * as XLSX from 'xlsx-js-style'

import PublishUtils from '../../../helpers/PublishUtils'

class BBTable extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/w2ui/table.html`

  constructor() {
    super()

    this.scrolling = 'true'
    this.noEvents = false
    this.usesParameters = false
  }

  getParameters() {
    return this.props.wraptext ? "?wraptext=true" : ""
  }

  static getDescription() {
    return {
      name: 'BBTable',
      component: BBTable,
      label: 'BB Table widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the table use any parameter. Optionally use Check parameter extension for boolean values. Optionally use the Fix extension (?parameternameFix) to restrict the amount of numbers after the decimal for each parameter. Optionally use ?background for background coloring ',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'colSizes',
          type: 'array',
          label: 'optional array of col sizes. 100px,50%,350px. Needs a widgetrefresh when changed',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label: 'Published uri variabele for other widgets.',
        },

        {
          name: 'replaceHeaderJSON',
          type: 'text',
          label: 'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
        },
        {
          name: 'searchbar',
          type: 'boolean',
          label: 'show searchbar (widget refresh necessary)',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
        {
          name: 'uselocalnumberformat',
          type: 'boolean',
          label: 'use local number format',
        },
        {
          name: 'downloadbutton',
          type: 'boolean',
          label: 'add toolbar with download button (widget refresh necessary)',
        },
        {
          name: 'wraptext',
          type: 'boolean',
          label: 'wrap text in the table',
        },
        {
          name: 'excelheadertext',
          type: 'text',
          label: 'headertext for row 1 when downloading this table in excel',
        },

        {
          name: 'summaryFields',
          type: 'text',
          label: 'add summary below using the following csv sparql parameters (costs, profit)',
        },
        {
          name: 'filename',
          type: 'text',
          label: 'xlsx filename',
        },
      ],
    }
  }

  setExcelColsWidth(array, headerKeys) {}

  resolveParameterValue = (value) => {
    return PublishUtils.processStringForParameters(this, value)
  }

  exportToSpreadsheet = (data, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    // Desired file extesion
    const fileExtension = '.xlsx'
    //Create a new Work Sheet using the data stored in an Array of Arrays.

    const workSheet = XLSX.utils.aoa_to_sheet(data)
    var header = data[0]
    var wscols = []
    var aantal = 0
    for (var n in header) {
      var ascii = String.fromCharCode(65 + aantal) + '1'
      aantal++

      workSheet[ascii].s = {
        font: {
          // name: "Calibri",
          sz: 12,
          bold: true,
          //  color: { rgb: "FFFFFFFF" },
        },
      }
      wscols.push({ wpx: 150 })
    }

    //console.log(this.setExcelColsWidth(data[0]));
    workSheet['!cols'] = wscols

    var filename = this.props.filename
    if (filename == null) filename = 'download'
    filename = PublishUtils.processStringForParameters(this, filename)

    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ['data'],
    }
    // Exporting the file with the desired name and extension.
    // const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
    const fileData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(fileData, filename + fileExtension)
  }
}

export default BBTable
