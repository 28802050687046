import React from 'react'
//import ReactDOM from 'react-dom'


import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux'
import { store } from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./components/mui/theme"
//import * as serviceWorker from './serviceWorkerRegistration'




const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
</Provider>);
/*
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
//serviceWorker.register();
