import { LoginState } from "../redux/login"
import { connect } from "react-redux"
import { ReactNode } from "react"
import { Route } from "react-router-dom"
import { RouteProps } from "react-router"
import { Login } from "./login/Login"

function ProtectedRouteComponent(props: {
  loginState: LoginState,
  children: ReactNode
} & RouteProps) {
  const { loginState, children, component, ...rest } = props
  return (
    <Route
      {...rest}
      component={loginState.isLoggedIn ? component : Login}
    />
  )
}

function mapStateToProps(state: any) {
  return { loginState: state.login }
}

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent)
