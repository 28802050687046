import React, { useState, useEffect } from "react"
import axios from "axios"

export function VirtuosoExport() {
  const [ allowed, setAllowed ] = useState(false)
  const [ graphs, setGraphs ] = useState<string[]>([])
  const [ formats, setFormats ] = useState<string[]>([])

  useEffect(() => {
    async function load() {
      setAllowed(false)

      let isAllowed = false
      try {
        isAllowed = (await axios.get("/servlets/cgi/api/virtuoso/export/allowed")).data
        setAllowed(isAllowed)
      } catch (e) {
        setAllowed(false)
      }

      if (isAllowed) {
        const graphs = (await axios.get("/servlets/cgi/api/virtuoso/export/graphs")).data
        setGraphs(graphs)

        const formats = (await axios.get("/servlets/cgi/api/virtuoso/export/formats")).data
        setFormats(formats)
      }
    }
    load()
  }, [])

  if (!allowed) {
    return <p>You must first log in to the User Management app in order to access the export functionality.</p>
  }

  return (
    <div>
      <h1>Export RDF {allowed+''}</h1>
      <form action="/servlets/cgi/api/virtuoso/export" method="post">
        <p>
          Graph:
          <select name="graph" required>
            <option>-- Select a graph</option>
            {graphs.map(graph => (
              <option key={graph} value={graph}>
                {graph}
              </option>
            ))}
          </select>
        </p>
        <p>
          Format:
          <select name="format" required>
            <option>-- Select a format</option>
            {formats.map(format => (
              <option key={format} value={format}>
                {format}
              </option>
            ))}
          </select>
        </p>
        <button type="submit">Export</button>
      </form>
    </div>
  )
}