import React, { useEffect, useState } from "react"
import { repoLogin, RepoLoginCredentials } from "../../api/repo-login"
import { SubmitHandler, useForm } from "react-hook-form"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { LoginButtonBar, LoginPageLayout, SsoButton } from "./LoginPageLayout"
import { LoginState } from "../../redux/login"
import axios from "axios"

type SsoConfig = {
  uri: string
  label: string
  comment: string
  ssoClientId: string
  repositories: string[]
  enabled: boolean
  fullAccess: boolean
  image: string
}

function LoginBBComponent(props: {
  loginState: LoginState
} & RouteComponentProps) {
  const {
    register,
    handleSubmit,
    formState
  } = useForm<RepoLoginCredentials>()
  const [ errorReason, setErrorReason ] = useState<string | null>(null)
  const [ ssoConfigs, setSsoConfigs ] = useState<SsoConfig[]>([])

  const repoInfo = getRepoInfoFromSearchParameters()
  useEffect(() => {
    if (!repoInfo?.repo) return
    fetchSsoConfigs()
   .then(ssoConfigs => setSsoConfigs(ssoConfigs))
   .catch(() => {})

    async function fetchSsoConfigs() {
      const response = await axios.get<SsoConfig[]>(`/servlets/cgi/api/ssoconfig/repo/${repoInfo?.repo}/viewer/${repoInfo?.viewerPath}`, {
        headers: {
          'Accept': 'application/json'
        }
      })

      if (!response.headers['content-type'].includes('application/json')) {
        return []
      }

      return response.data
    }

  }, [ repoInfo?.repo ])

  const doLogin: SubmitHandler<RepoLoginCredentials> = async loginCredentials => {
    if (!repoInfo) return

    try {
      setErrorReason(null)

      const resp = await repoLogin(loginCredentials, repoInfo.repo, repoInfo.repoUrl)
      if (resp.success) {
       
        props.history.replace(props.location.state || '/')

        // @ts-ignore
        if (window.CONNECTED_APPS_STATIC) {
          // Workaround:
          // React-router's history.replace does not always rerender
          // when <Route path="*" /> is used (static apps),
          // causing a blank page.
          // https://stackoverflow.com/a/61596862
          window.location.reload()
        }
      } else {
        setErrorReason(resp.reason)
      }
    } catch (e) {
      setErrorReason("Login error")
    }
  }

  if (!repoInfo) {
    return (
      <LoginPageLayout>
        Invalid repository information.
      </LoginPageLayout>
    )
  }

  const isSuperAdmin = props.loginState.isLoggedIn && props.loginState.user.role === 'superadmin'

  return (
    <LoginPageLayout>
      <h1>Welcome</h1>
      <form onSubmit={handleSubmit(doLogin)}>
        <p>
          This app requires a login.{' '}
          {isSuperAdmin && (
            `Repository: ${repoInfo.repo}`
          )}
        </p>

        <label>
          Username
          <input type="username" required
                 {...register('username', { required: true })} />
        </label>

        <label>
          Password
          <input type="password" required
                 {...register('password', { required: true })} />
        </label>

        <LoginButtonBar disabled={formState.isSubmitting}
                        isLoggingIn={formState.isSubmitting}
                        error={errorReason} />

        {ssoConfigs.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '.75rem', marginTop: '1.5rem' }}>
            {ssoConfigs.map(ssoConfig => (
              <SsoButton key={ssoConfig.uri} href={`/servlets/cgi/api/sso/authorize/${ssoConfig.ssoClientId}?ssoSuccessRedirectUrl=${props.location.state}`}>
                <img
                  src={ssoConfig.image}
                  alt="" />
                <span>Sign in with {ssoConfig.label}</span>
              </SsoButton>
            ))}
          </div>
        )}

      </form>
    </LoginPageLayout>
  )
}

function getRepoInfoFromSearchParameters() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const id = urlParams.get('id')
  if (id == null) return null

  const parametersString = atob(decodeURIComponent(id))
  const usp = new URLSearchParams(parametersString)
  const repoUrl = usp.get('repoUrl')
  const repo = usp.get('repo')
  const viewerPath = usp.get('viewerPath')
  if (repoUrl == null) return null

  return { repo, repoUrl, viewerPath }
}

function mapStateToProps(state: any) {
  return {
    loginState: state.login
  }
}

export const RepoLogin = connect(mapStateToProps)(LoginBBComponent)
