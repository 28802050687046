export function getCgiUrl(
  cgiService: '' | 'login' | 'logout' | 'isloggedin' | 'sparql',
  repoUrl: string,
  localRepoUrl?: string
): string {
  const absoluteRepoUrl = getAbsoluteRepoUrl(repoUrl, localRepoUrl)
  return absoluteRepoUrl.replace('sparql', cgiService)
}

export function getAbsoluteRepoUrl(repoUrl: string, localRepoUrl?: string) {
  const { origin } = window.location
  const url = origin.includes('localhost') && localRepoUrl ? localRepoUrl : repoUrl

  if (url.startsWith('http://') || url.startsWith('https://')) return url
  if (url.startsWith('/')) return origin + url
  return `${origin}/${url}`
}
