import { ToggleSwitch } from '../ToggleSwitch'
import { IconButton } from '@mui/material'
import { Icon } from './Icon'
import styles from './ToggleWidget.module.css'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const toggleWidgetDescription = {
  name: 'ToggleWidget',
  component: ToggleWidget,
  label: 'Toggle Widget',
  variables: [
    {
      name: 'appearance',
      type: 'select',
      label: 'Choose your preferred appearance',
      options: [
        {
          label: 'Icon',
          value: 'icon',
        },
        {
          label: 'Checkbox',
          value: 'checkbox',
        },
        {
          label: 'Toggle Switch',
          value: 'toggleSwitch',
        },
      ],
    },
    {
      name: 'iconImage',
      type: 'text',
      label:
        'name of the Font Awesome icon you want to use',
    },
    {
      name: 'publishTopic',
      type: 'text',
      label:
        'topic to publish binary state to',
    },
    {
      name: 'enabledBgColor',
      type: 'text',
      label: 'Custom Styling: Enabled background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
    },
    {
      name: 'enabledIconColor',
      type: 'text',
      label: 'Custom Styling: Enabled icon-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
    },
    {
      name: 'disabledBgColor',
      type: 'text',
      label: 'Custom Styling: Disabled background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
    },
    {
      name: 'disabledIconColor',
      type: 'text',
      label: 'Custom Styling: Disabled icon-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
    },
  ],
}

function ToggleWidget(props: {
  pubsub: any
  appearance: 'icon' | 'checkbox' | 'toggleSwitch'
  iconImage: IconProp
  publishTopic: string
  enabledBgColor:string
  enabledIconColor: string
  disabledBgColor:string
  disabledIconColor: string
  publish: (topic: string, value: unknown) => void
}) {
  const appearance = props.appearance || 'icon'
  const iconImage = props.iconImage || 'filter'
  const publishTopic = props.publishTopic || 'defaultTopic'
  const handleClick = () => {
    if (publishTopic) {
      props.publish(publishTopic, !props.pubsub[publishTopic])
    }
  }
  return (
    <div style={{
      '--icon-color': props.disabledIconColor,
      '--bg-color': props.disabledBgColor,
      '--active-icon-color': props.enabledIconColor,
      '--active-bg-color': props.enabledBgColor,
    } as {}}>
      {appearance == 'checkbox' && <input type="checkbox" name="example" checked={props.pubsub[publishTopic]} onClick={handleClick}/>}
      {appearance == 'toggleSwitch' && <ToggleSwitch isToggled={props.pubsub[publishTopic]} onClick={handleClick}/>}
      {appearance == 'icon' && (
        <IconButton
          className={styles.iconButton}
          onClick={handleClick}
          data-active={props.pubsub[publishTopic]}
        >
          <Icon icon={iconImage} />
        </IconButton>
      )}
    </div>
  )
}
