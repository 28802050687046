import { useDialog } from "../hooks/use-dialog"

import { HelpDialog } from "./HelpDialog"
import { useMemo } from "react"

export function FontListButton() {
  const show = useDialog()
  return (
    <a href="#" onClick={e => {
      e.preventDefault()
      show(HelpDialog, {
        component: FontList
      })
    }}>
      Show available fonts...
    </a>
  )
}

const AVAILABLE_FONTS = [
  "Exo",
  "Maison Neue",
  "Roboto"
] as const

export function FontList(props: {
  onClose: () => void
}) {
  return (
    <div>
      <h3>Click to copy</h3>
      <ul>
        {AVAILABLE_FONTS.map(font => (
          <li key={font}>
            <a href="#" onClick={async e => {
              e.preventDefault()
              await navigator.clipboard.writeText(font)
              props.onClose()
            }}>
              {font}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}