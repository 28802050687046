import { getAbsoluteRepoUrl, getCgiUrl } from '../helpers/url'







  export const getAndSetDefaultParameterValues=(props,myPublish)=> 
  {
      var r={}
  try {
    //cgi parameter
 //   console.log(this.props);
 

var ss=getAbsoluteRepoUrl(props.repoURL).split('sparql');
 let cgi = ss[0];
 //let cgi = props.repoURL.replace('sparql', '')
 var repoUrl="";
 if(ss.length>1)
 {
    repoUrl=ss[1].substring(1)+"/";
   // myPublish('repo', repoUrl);
    r['repo']=repoUrl;
    window.repo=repoUrl;
   
 }
 //myPublish('cgi', cgi);
 //myPublish('repoUrl', getAbsoluteRepoUrl(props.repoURL));
 //myPublish('now',  Date.now());
 r['cgi']=cgi;
 r['repoUrl']= getAbsoluteRepoUrl(props.repoURL);
 r['now']=  Date.now();


    window.cgi=cgi;
    window.repoURL=getAbsoluteRepoUrl(props.repoURL);
   var p_v = {};
    let params = props.parameterValues
    if (params != null) {
     // console.log("handle default parameter values",params);
    
        params = JSON.parse(params)

        for (let n in params) {
       //   console.log("publishing default parameters ",n,params[n]);
          p_v[n]= params[n];
         
        }
     
      }
  } catch (e) {
   // console.log('error (1) parsing default values json (managed)',e);
    
  }


  if (props.parseUrlProperties)
  {
   // console.log("handle url properties if available");
    var me=this;
   
    try
    {
      let search=window.top.window.location.search;
      let u=new URLSearchParams(search);
      let entries = u.entries();
      for(const entry of entries) {
   
       var key2 = entry[0];
       var value2=entry[1];
       p_v[key2]=value2;

       // this.myPublish(entry[0],entry[1]);
      }

 //     console.log("end url properties ");
    }
    catch(e){console.log(e);}
  }

  if (document.userParameters)
  {
    //  console.log("user login properties",document.userParameters);
      try
      {
           for (var key in document.userParameters)
           {
            var value=document.userParameters[key];
          //  console.log(key,value);
            p_v[key]=value;
            
           }
      }
      catch(e)
      {}
  }

   for (var key in p_v)
   {
    
        r[key]=p_v[key];
   }
   return r;


}