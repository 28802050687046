import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { FormGroup as _FormGroup, Input as _Input, Label as _Label } from 'reactstrap'
import { useDebounce } from '../../hooks/use-debounce'

// Workaround due to incorrect typing of the class-component instances
const FormGroup = _FormGroup as any
const Input = _Input as any
const Label = _Label as any

const DEFAULT = {
  DEBOUNCE_TIME: 1, //second
  WIDTH: '100%',
}

export const textInputWidgetDescription = {
  name: 'Text input',
  component: TextInput,
  label: 'Text input',
  variables: [
    {
      name: 'label',
      type: 'text',
      label: 'Label above input-field',
    },
    {
      name: 'topicToPublish',
      type: 'text',
      label:
        'Topic to which to publish the user input',
    },
    {
      name: 'debounceTimeInSeconds',
      type: 'text',
      label: 'Seconds to wait after user input to broadcast value to topic',
    },
    {
      name: 'width',
      type: 'text',
      label: 'Width of text input (use css length value; default is "100%")',
    }
  ],
}

export function TextInput(props: {
  label?: string
  topicToPublish?: string
  debounceTimeInSeconds: string
  width: string
  publish: (topic: string, value: string) => void
}) {
  console.log(props.debounceTimeInSeconds, typeof props.debounceTimeInSeconds)
  const parsedDebounceTime = Number(props.debounceTimeInSeconds || DEFAULT.DEBOUNCE_TIME) * 1000

  const [text, setText] = useState('')

  const publishText = (text: string) => {
    if (props.topicToPublish) props.publish(props.topicToPublish, text)
  }

  useDebounce(() => publishText(text), parsedDebounceTime, [text])
  useEffect(() => publishText(text), [props.topicToPublish]) // eslint-disable-line react-hooks/exhaustive-deps

  if (Number.isNaN(parsedDebounceTime))
    return <div>Error: configured waiting time is not a number: "{props.debounceTimeInSeconds}"</div>

  return (
    <FormGroup style={{padding: '0.25rem', width: '100%'}}>
      {props.label &&
        <Label style={{marginLeft: '0.25rem', marginBottom: '0.25rem'}}>{props.label}</Label>
      }
      <Input
        onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        style={{width: props.width || DEFAULT.WIDTH}}
      />
    </FormGroup> as ReactNode
  )
}
