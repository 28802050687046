import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './redux/rootReducer'

function configureStore(initialState = {}) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
          replacer: replaceSets
        }
      })
      : compose

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  )
}

/**
 * Proper ES6 Set diff display in redux devtools
 * (serialize: true seems not to be sufficient, state works but diffs do not)
 */
function replaceSets(key, value) {
  if (value instanceof Set) return [ ...value ]
  return value
}

export const store = configureStore()

