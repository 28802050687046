import React from "react"
import { VirtuosoExport } from "./VirtuosoExport"
import { VirtuosoImport } from "./VirtuosoImport"

export function VirtuosoImportExport() {
  return (
    <div style={{ padding: '3em' }}>
      <VirtuosoExport />
      <hr />
      <VirtuosoImport />
    </div>
  )
}