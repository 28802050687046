import React from "react"
import Header from "../Header"
import styles from './LoginPageLayout.module.css'
import "typeface-exo"
// @ts-ignore
import { Helmet } from "react-helmet"
import { Icon } from "../widgets/Icon"
import clsx from "clsx"

export function LoginPageLayout(props: {
  children: React.ReactNode
}) {
  return (
    <div className={styles['login-app']}>
      <Helmet>
        <title>Wistor</title>
        <link rel="icon" type="image/png" href="/favicon.png" sizes="16x16" />
      </Helmet>

      {/* @ts-ignore */}
      <Header modern noLoginButton />

      <div className={styles['login-container']}>
        <div className={styles['login-main']}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export function LoginButtonBar(props: {
  error?: string | null,
  isLoggingIn?: boolean
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'className'>) {
  const { error, isLoggingIn, ...otherProps } = props

  return (
    <div className={styles['login-button-bar']}>
      {isLoggingIn && (
        <div className={styles['login-spinner']}>
          <Icon icon="spinner" spin />
        </div>
      )}
      {error && (
        <div className={styles['login-error']}>{error}</div>
      )}
      <button className={styles['login-button']}
              type="submit"
              {...otherProps}>Log in</button>
    </div>
  )
}

export function SsoButton(props: {
  href: string,
  children: React.ReactNode
}) {
  return (
    <a href={props.href}
       className={clsx(
      styles['login-button'],
      styles['login-button-sso']
    )}>
      {props.children}
    </a>
  )
}
