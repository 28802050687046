import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import axios from "axios"

interface FormData {
  graph: string
  files: FileList | null
}

export function VirtuosoImport() {
  const [ allowed, setAllowed ] = useState(false)
  const { register, handleSubmit, formState: { isSubmitting }, reset } = useForm<FormData>()

  useEffect(() => {
    async function checkAllowed() {
      setAllowed(false)

      try {
        const isAllowed = (await axios.get("/servlets/cgi/api/virtuoso/import/allowed")).data
        setAllowed(isAllowed)
      } catch (e) {
        setAllowed(false)
      }
    }
    checkAllowed()
  }, [])

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const formData = new FormData();
      formData.append('graph', data.graph)
      if (data.files) {
        for (let i = 0; i < data.files.length; i++) {
          formData.append('file', data.files[i])
        }
      }

      await axios.post('/servlets/cgi/api/virtuoso/import', formData, {
        method: "post",
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      alert('Success')

      reset();
    } catch (error) {
      alert('Error: ' + error)
      console.error('Error:', error);
    }
  }

  if (!allowed) {
    return <p>You must first log in to the User Management app in order to access the import functionality.</p>
  }

  return (
    <div>
      <h1>Upload RDF</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          Default graph URL<br />
          <input
            type="text"
            style={{ width: '300px '}}
            {...register('graph')} />
        </p>

        <p>
          File(s) (ttl, trig, rdf/xml, etc.)<br />
          <input
            type="file"
            multiple
            {...register('files')} />
        </p>

        <button
          type="submit"
          disabled={isSubmitting}
          color="primary"
        >
          Upload
        </button>
        {isSubmitting && 'Importing...'}
      </form>
    </div>
  );
}