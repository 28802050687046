import { lazy } from 'react';

export default class BBSparnaturalDescriptor{


static getDescription() {
    return {
      name: 'BBSparnatural',
      component: lazy(() =>  import('./BBSparnatural')),
      label: 'BB Sparnatural',
      variables: [
       
          {
            name: 'configttlurl',
            type: 'text',
            label: 'config ttl url',
          },
          {
            name: 'configttl',
            type: 'text',
            label: 'config ttl as a string',
          },
        {
          name: 'query',
          type: 'yasgui',
          label: 'perhaps create sparnatural config using sparql results?  ',
        }
    ]
}
}
}
