
import { lazy } from 'react';
import PublishUtils from '../../../helpers/PublishUtils';

class BBPropertyPanelDescriptor {
  

  static getDescription() {
    return {
      name: 'BBPropertyPanel',
      component: lazy(() =>  import('./BBPropertyPanel')),
      label: 'BBPropertyPanel',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the property panel. Use ?property and ?value. optionally use  ?beschrijving  ?beschrijvingValue ?literal ?pred  ?iconColor ?icon',
        },
        {
          name: 'childParameterText',
          type: 'text',
          label:
            'string to replace in sparql with uri for getting child info (e.g. {{uri}})',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'editable',
          type: 'boolean',
          label:
            'allow edits. this needs extra ?graph ?datatype ?uri parameters',
        },
        {
          name: 'ruleTagAfterSubmit',
          type: 'text',
          label: 'run rule with tag after succesful submit',
        },
        {
          name: 'parameters',
          type: 'text',
          label:
            'extra parameters send in JSON format,Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  
}

export default BBPropertyPanelDescriptor
