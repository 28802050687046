import React from 'react'
import styles from './Tab.module.css'
import Area from '../Area'
import { Icon } from './Icon'
import { Button } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CommonWidgetProps, ConfigurablePropsFromDescription, getSubscription, isString, isValidIndex } from '../../helpers/widget'

type Props = CommonWidgetProps & ConfigurableProps & SpecificWidgetProps
type ConfigurableProps = ConfigurablePropsFromDescription<typeof tabDescription['variables']>
type SpecificWidgetProps = { areas: {}[] }

type State = {
  selectedIndex: number
  hideTabs: string[]
  areas: {}[]
  id: number
}

const APPEARANCE = {
  TRADITIONAL: 'TRADITIONAL',
  CAROUSEL_WITHOUT_BUTTONS: 'CAROUSEL_WITHOUT_BUTTONS',
  CAROUSEL_WITH_BUTTONS: 'CAROUSEL_WITH_BUTTONS',
} as const

export default class Tab extends React.Component<Props, State> {
  static tabId = 0
  state: State = { selectedIndex: 0, hideTabs: [], areas: [], id: Tab.tabId++ }


  static getDescription = () => tabDescription

  static registeredTabComponents = [] //registering all tabs for 'back button'

  getTabId() {
    return this.state.id
  }

  componentDidMount() {
    this.updateTabs(null, this.props.hideTabs, this.props.tabSelectionSubscription)
  }

  componentDidUpdate(prevProps: Props) {
    this.updateTabs(prevProps, this.props.hideTabs, this.props.tabSelectionSubscription)
  }

  updateTabs(
    prevProps: null | Props,
    hideTabsTopic: undefined | null | string,
    tabIndexTopic: undefined | null | string,
  ) {
    const tabIndex = getSubscription(prevProps?.pubsub, this.props.pubsub, tabIndexTopic, isValidIndex)
    if (!tabIndex.isValid && tabIndex.current != null && tabIndex.topic != null)
      return alert(`Value "${tabIndex.current}" from topic "${tabIndex.topic}" is not a valid index"`)

    const hideTabs = getSubscription(prevProps?.pubsub, this.props.pubsub, hideTabsTopic, isString)
    if (!hideTabs.isValid && hideTabs.current != null && hideTabs.topic != null)
      return alert(`Value "${hideTabs.current}" from topic "${hideTabs.topic}" is not a string"`)

    if (!tabIndex.hasChanged && !hideTabs.hasChanged)
      return

    let newSelectedIndex = this.state.selectedIndex
    if (tabIndex.isValid && tabIndex.hasChanged) newSelectedIndex = tabIndex.current

    let newHideTabs: string[] = []
    if (hideTabs.isValid && hideTabs.hasChanged) newHideTabs = hideTabs.current.split(',')

    if (this.props.tabs?.length && this.props.hideTabIDs && newSelectedIndex != null) {
      const tabHideId = this.props.hideTabIDs[newSelectedIndex]
      const firstNonHiddenIndex = getFirstNonHiddenTab(this.props.tabs, this.props.hideTabIDs, newHideTabs)
      if (firstNonHiddenIndex != null && newHideTabs?.includes(tabHideId))
        newSelectedIndex = firstNonHiddenIndex
    }

    if (newHideTabs) this.setState({ hideTabs: newHideTabs })
    if (newSelectedIndex !== this.state.selectedIndex) this.setState({ selectedIndex: newSelectedIndex })
  }

  onTabClick = (index: number) => {
    let tabSelectionSubscription = this.props.tabSelectionSubscription

    if (tabSelectionSubscription) this.props.publish(tabSelectionSubscription, index)
    this.setState({ selectedIndex: index })
  }

  navigateToNextTab() {
    if (!this.props.tabs) return
    this.onTabClick(
      this.state.selectedIndex >= (this.props.tabs.length - 1)
        ? 0
        : this.state.selectedIndex + 1
    )
  }

  render() {
    let mode = this.props.mode
    return (
      <div
        className={styles.main + (mode === 'edit' ? '' : ' ' + styles.viewMode) + ' ' + styles[this.props.panelstyle]}
      >
        {(!this.props.appearance || this.props.appearance === APPEARANCE.TRADITIONAL) && (
          <TraditionalTabs
            tabs={this.props.tabs ?? []}
            icons={this.props.icons as any}
            selectedIndex={this.state.selectedIndex}
            hideTabs={this.state.hideTabs ?? []}
            hideTabIDs={this.props.hideTabIDs ?? []}
            inactiveTabsOnlyIcons={Boolean(this.props.inactiveTabsOnlyIcons)}
            onTabClick={this.onTabClick}
          />
        )}

        {this.props.tabs?.map((tab, index) => {
          let areaConfig = this.props.areas[index] || {}

          let selected = index == this.state.selectedIndex
          // console.log(index,this.state.selectedIndex,selected);
          if (this.props.withinSelectedTab != null) {
            if (this.props.withinSelectedTab == false) {
              selected = false
              //  console.log("TEST FF NIET SELECTED TAB WANT TAB IS NIET ZICHTBAAR");
            }
          }

          return (
            <div key={index} className={styles.areaContainer} style={{ display: selected ? 'flex' : 'none' }}>
              <Area
                {...this.props}
                key={index}
                index={index}
                config={areaConfig}
                onChange={this.props.onChange}
                withinSelectedTab={selected}
                onClick={this.props.carouselClickNavigation ? () => this.navigateToNextTab() : undefined}
              />
            </div>
          )
        })}

        {(this.props.appearance === APPEARANCE.CAROUSEL_WITHOUT_BUTTONS ||
          this.props.appearance === APPEARANCE.CAROUSEL_WITH_BUTTONS) && (
          <CarouselTabs
            withButtons={this.props.appearance === APPEARANCE.CAROUSEL_WITH_BUTTONS}
            tabs={this.props.tabs ?? []}
            selectedIndex={this.state.selectedIndex}
            onTabClick={this.onTabClick}
          />
        )}
      </div>
    )
  }
}

const tabDescription = {
  name: 'Tab',
  component: Tab,
  label: 'Tab Layout',
  variables: [
    {
      name: 'appearance',
      type: 'select',
      label: 'Appearance',
      options: [
        {
          label: 'Traditional',
          value: APPEARANCE.TRADITIONAL,
        },
        {
          label: 'Carousel without buttons',
          value: APPEARANCE.CAROUSEL_WITHOUT_BUTTONS,
        },
        {
          label: 'Carousel with buttons',
          value: APPEARANCE.CAROUSEL_WITH_BUTTONS,
        },
      ],
    },
    {
      name: 'carouselClickNavigation',
      type: 'boolean',
      label: 'Enable click navigation (only for carousel appearance)',
    },
    {
      name: 'tabs',
      type: 'array',
      label: 'Tabs',
    },
    {
      name: 'tabSelectionSubscription',
      type: 'text',
      label: 'Variabele to listen to for tab selection',
    },
    {
      name: 'hideTabIDs',
      type: 'array',
      label: 'tab name (ids) that can be used to hide a tab.',
    },
    {
      name: 'hideTabs',
      type: 'text',
      label:
        'Variabele to listen to for tab visualisation. Use tab names to hide the tabs',
    },
    {
      name: 'icons',
      type: 'array',
      label: 'fa icon names when no icon is defined. ',
    },
    {
      name: 'inactiveTabsOnlyIcons',
      type: 'boolean',
      label: 'Inactive tabs will only have icons if icons are defined'
    },
    {
      name: 'initialTabSelection',
      type: 'text',
      label: 'index of the initial selected tab',
    },
    {
      name: 'areas',
      type: 'json',
      // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
      label: 'area definitions',
    },
  ],
} as const

function TraditionalTabs(props: {
  tabs: string[]
  hideTabIDs: string[]
  hideTabs: string[]
  icons: IconProp[]
  selectedIndex: number
  inactiveTabsOnlyIcons: boolean
  onTabClick: (index: number) => any
}) {
  return (
    <div className={styles.tabs}>
      {props.tabs.map((tabText, index) => {
        const tabHideId = props.hideTabIDs?.at(index)
        if (tabHideId && props.hideTabs?.includes(tabHideId)) return null

        let isSelected = index === props.selectedIndex
        const hideTabText = props.inactiveTabsOnlyIcons && props.icons && !isSelected
        return (
          <div
            key={index}
            className={styles.tab + (isSelected ? ' ' + styles.selected : '')}
            onClick={() => props.onTabClick(index)}
          >
            {props.icons != null && (
              <Icon
                icon={props.icons[index]}
                size="xs"
                transform="down-0; left-4"
              />
            )}
            {!hideTabText && tabText}
          </div>
        )
      })}
  </div>
  )
}

function CarouselTabs(props: {
  tabs: string[]
  selectedIndex: number
  withButtons: boolean
  prevIcon?: null | IconProp
  itemIcon?: null | IconProp
  nextIcon?: null | IconProp
  onTabClick: (index: number) => any
}) {
  return (
    <div className={styles.carouselTabsContainer}>
      {props.withButtons &&
        <Button
          onClick={() => {
            if (props.selectedIndex <= 0) return
            props.onTabClick(props.selectedIndex - 1)
          }}
          className={styles.carouselButton}
        >
          <Icon icon={props.prevIcon ?? 'caret-left'} />
        </Button>
      }
      {props.tabs?.map((tabLabel, i) => (
        <Button
          className={styles.carouselItem}
          key={tabLabel}
          onClick={() => props.onTabClick(i)}
        >
          <Icon
            data-selected={i === props.selectedIndex}
            icon={props.itemIcon ?? 'circle'}
          />
        </Button>
      ))}
      {props.withButtons &&
        <Button
          className={styles.carouselButton}
          onClick={() => {
            if (props.tabs && props.selectedIndex + 1 >= props.tabs.length) return
            props.onTabClick(props.selectedIndex + 1)
          }}
        >
          <Icon icon={props.nextIcon ?? 'caret-right'} />
        </Button>
      }
    </div>
  )
}

export function getFirstNonHiddenTab(tabs: string[], hideTabIds: string[], hideTabs: string[]) {
  for (let i = 0; i < tabs.length; i++) {
    const tabHideId = hideTabIds?.[i]
    if (tabHideId && !hideTabs?.includes(tabHideId)) return i
  }
}
