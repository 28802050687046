export default function handlePubSub(state = {}, action) {
  //console.log(action);

  switch (action.type) {
    case 'PUBLISH':
      {
     
        var timestamps={};
        var dn=Date.now();
        for (var n in action.data)
        {
           timestamps[n]=action.data[n];
           timestamps[n+"Timestamp"]="auto"+dn;
        }
        window.top.changes=timestamps; //used by popup button. I would like to make this more generic
      //  action.data=timestamps; // why is wistor not working when using this. so basically why can Wistor not handle multiple parameter changes at once?
      return {
        ...state,
        ...action.data,
      }
    }
    case 'PUBLISH2': {
      console.log('publish 2', state, action.data)
      return state
    }
    default:
      {
      //  console.log(action);
      return state
      }
  }
}
