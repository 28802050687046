import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBRDFViewer extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/static/iframes/rdfviewer/index.html`

  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBRDFViewer',
      component: BBRDFViewer,
      label: 'BB RDF Viewer',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: ' Use ?file. or upcoming blob parameters',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when an object is selected.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        PublishUtils.getResetValueDef(),
      ],
    }
  }

  state = { selectedUri: null }
}
export default BBRDFViewer
